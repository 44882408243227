import * as React from 'react';
import { Seo } from '../components/seo';
import { Link } from 'gatsby';
import { Layout } from '../layout';
import { BreadCrumb } from '../section/breadcrumb';
import FlowImg from '../assets/img/bg-flow.jpeg';

const FlowPage = () => {
  return (
    <Layout>
      <Seo
        title="仕事の流れ"
        description="お問い合わせから施工完了までの流れをご紹介します。ご質問等ございましたらお気軽にお問い合わせください。"
      />
      {/* <!-- ヘッダータイトル --> */}
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${FlowImg})` }}
          className="relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">FLOW</span>
            <span className="mt-2 text-white text-xs md:text-base">
              仕事の流れ
            </span>
          </h1>
          <div>
            <svg
              className="wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <BreadCrumb title="仕事の流れ" />
      {/* <!-- 仕事の流れ --> */}
      <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-3xl">
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">
              お問い合わせから施工完了まで
            </h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                1
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                お問い合わせ
              </h3>
            </div>
            <p className="py-2 text-xs tracking-wider leading-5 md:py-3 md:text-sm md:leading-6">
              まずは、お電話、メールにてお気軽にお問い合わせください。ご要望やお困りごとについて、納得いただけるまで丁寧にご説明、ご提案させていただきます。
            </p>
            <div className="flex flex-wrap items-center justify-center md:justify-between">
              <div className="flex items-center justify-center w-full md:justify-between md:w-1/2">
                <span className="text-sm tracking-wider">
                  TEL
                  <a
                    className="ml-2 text-xl tracking-wider"
                    href="tel:072-993-8253"
                  >
                    072-993-8253
                  </a>
                </span>
              </div>
              <div className="relative mx-auto my-4 w-fit-content md:mr-8">
                <Link
                  className="btn-background-slide group flex items-center justify-center mx-auto py-2 w-48 bg-primary-blue md:py-4 md:w-64"
                  to="/contact"
                >
                  <span className="z-10 group-hover:text-primary-blue text-white text-sm tracking-wider transition duration-300 ease-in-out md:text-base">
                    メールにてお問い合わせ
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                2
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                工事案件の設計・施主との打合せ
              </h3>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                3
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                見積もり作成
              </h3>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                4
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                実測・調査
              </h3>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                5
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                発注
              </h3>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                6
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                各下請け業者と段取り
              </h3>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                7
              </span>
            </div>
            <div className="w-px h-full bg-primary-blue"></div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                現場における施工監理
              </h3>
            </div>
          </div>
        </div>
        <div className="flex items-start">
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center justify-center border border-primary-blue rounded-full">
              <span className="w-8 h-8 text-center text-primary-blue text-xs leading-8 md:w-10 md:h-10 md:text-sm md:leading-10 md:leading-10">
                8
              </span>
            </div>
          </div>
          <div className="flex-grow mb-8 ml-4 lg:ml-8">
            <div className="py-1 md:py-2">
              <h3 className="text-primary-blue text-sm md:text-base lg:text-xl">
                工事完了後の精算
              </h3>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default FlowPage;
